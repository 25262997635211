const baseUrl = process.env.REACT_APP_PINLET_SERVER_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const cache = {};

/**
 * Genera una clave única para el endpoint y el payload
 * @param {string} endpoint - Nombre del endpoint
 * @param {object} payload - Datos para enviar en la solicitud
 * @returns {string} - Clave única para el caché
 */
const generarClaveCache = (endpoint, payload) => {
  return `${endpoint}_${JSON.stringify(payload)}`;
};

/**
 * Función para obtener datos de un endpoint con caché local
 * @param {string} endpoint - Nombre del endpoint
 * @param {object} payload - Datos para enviar en la solicitud
 * @returns {Promise<any>} - Respuesta del servidor o datos del caché
 */
export const obtenerDatos = async (endpoint, payload) => {
  const claveCache = generarClaveCache(endpoint, payload);

  if (cache[claveCache]) {
    console.log("Datos obtenidos del caché:", cache[claveCache]);
    return cache[claveCache];
  }

  try {
    const response = await fetch(`https://pinlet.app/apiRest6/corp/v1/${endpoint}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      console.error(response);
      throw new Error(`Error en la solicitud al endpoint: ${endpoint}`);
    }
    const data = await response.json();
    cache[claveCache] = data;
    console.log("Datos obtenidos del servidor:", data);
    return data;
  } catch (error) {
    console.error(`Error al obtener datos del endpoint: ${endpoint}`, {
      endpoint,
      payload,
      error: error.message,
    });
    throw error;
  }
};
