import './caracteristicasMarcacion.css';
import { useState } from 'react';

export const CaracteristicasMarcacion = ({ data, imgTipo }) => {


    const [itemSelected, setItemSelected] = useState(data[0]);



    const gotoSlide = (item) => {
        console.log(item);
        setItemSelected(item);
        const targetElement = document.getElementById(`secciones_container`);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    return (
        <>
            <div className='caracteristicas_marcacion'>
                {data.map((item, index) => (
                    <button key={index} onClick={() => gotoSlide(item)} className='caracteristica'>
                        <img src={item.imgBtn}></img>
                        <p>{item.caracteristica}</p>
                    </button>
                ))}
            </div>

            <div className='slide-secciones'>
                <div id="secciones_container" className='secciones_container'>
                    <div className='seccion'>
                        <div className='texto'>
                            <h3>{itemSelected.titulo}</h3>
                            <div className='imagenes_section'>
                                {data.map((item, index) => (
                                    <button key={index} onClick={() => gotoSlide(item)}>
                                        <img className={itemSelected === item ? 'seleccionada' : ""} src={item.img}></img>
                                    </button>
                                ))}
                            </div>
                            <p>{itemSelected.texto}</p>
                        </div>
                    </div>
                </div>
                <div className='img_slider'>
                    <img src={imgTipo} />
                </div>
            </div>
        </>
    )
}