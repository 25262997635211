import './checkIn.css'
import { useRef } from 'react';
import marca from '../../../assets2/textoLogoPinlet.png';
import ig1 from '../../../assets2/productos/checkIn/ig1.jpg'
import ig2 from '../../../assets2/productos/checkIn/ig2.jpg'
import ig3 from '../../../assets2/productos/checkIn/ig3.jpg'
import ig4 from '../../../assets2/productos/checkIn/ig4.jpg'
import ig5 from '../../../assets2/productos/checkIn/ig5.jpg'
import ig6 from '../../../assets2/productos/checkIn/ig6.jpg'

import adaptable from '../../../assets2/productos/checkIn/calendar.svg'
import seccionable from '../../../assets2/productos/checkIn/users.svg'
import modulable from '../../../assets2/productos/checkIn/module.svg'
import reportable from '../../../assets2/productos/checkIn/report.svg'

import img_section1 from '../../../assets2/productos/checkIn/section_img1.png'
import img_section2 from '../../../assets2/productos/checkIn/section_img2.png'
import img_section3 from '../../../assets2/productos/checkIn/section_img3.png'
import img_section4 from '../../../assets2/productos/checkIn/section_img4.png'

import horario from '../../../assets2/productos/checkIn/horario.png';

import Slider from "react-slick";

import { useLanguage } from '../../../contexts/LanguageContext';
import { CaracteristicasMarcacion } from '../../../sections/CaracteristicasMarcacion/CaracteristicasMarcacion';



export const CheckIn = () => {

    const { language, translations } = useLanguage();

    const settingsCarrusel = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        centerMode: true,
        centerPadding: "0",
        responsive: [
            {
                breakpoint: 1250,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "20px",
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    centerMode: false,
                },
            },
        ],
    };


    const data = [
        {
            caracteristica: translations[language].products.checkinSeccion.data1.adelanto,
            imgBtn: adaptable,
            titulo: translations[language].products.checkinSeccion.data1.cabeza,
            img: img_section1,
            texto: translations[language].products.checkinSeccion.data1.texto
        },
        {
            caracteristica: translations[language].products.checkinSeccion.data2.adelanto,
            imgBtn: seccionable,
            titulo: translations[language].products.checkinSeccion.data2.cabeza,
            img: img_section2,
            texto: translations[language].products.checkinSeccion.data2.texto
        },
        {
            caracteristica: translations[language].products.checkinSeccion.data3.adelanto,
            imgBtn: modulable,
            titulo: translations[language].products.checkinSeccion.data3.cabeza,
            img: img_section3,
            texto: translations[language].products.checkinSeccion.data3.texto
        },
        {
            caracteristica: translations[language].products.checkinSeccion.data4.adelanto,
            imgBtn: reportable,
            titulo: translations[language].products.checkinSeccion.data4.cabeza,
            img: img_section4,
            texto: translations[language].products.checkinSeccion.data4.texto
        },
    ];

    return (
        <div className="main_check_in">
            <div className='contenedor'>
                <div className='titulo'>
                    <img src={marca} alt='texto logo pinlet'></img>
                    <h3>{translations[language].products.checkIn}</h3>
                </div>
                <div className='informacion'>
                    <p className='descripcion'>
                    {translations[language].products.checkinSeccion.descripcion}
                    </p>
                </div>
                <div className='manual'>
                    <div className='titulo_marcacion'>
                        <h3>{translations[language].products.checkinSeccion.titulo}</h3>
                        <h4>{translations[language].products.checkinSeccion.subtitulo} </h4>
                    </div>
                    <div className='slide-carrusel'>
                        <Slider {...settingsCarrusel}>
                            <div className='img_check_in_ig'>
                                <img src={ig1} />
                            </div>
                            <div className='img_check_in_ig'>
                                <img src={ig2} />
                            </div>
                            <div className='img_check_in_ig'>
                                <img src={ig3} />
                            </div>
                            <div className='img_check_in_ig'>
                                <img src={ig4} />
                            </div>
                            <div className='img_check_in_ig'>
                                <img src={ig5} />
                            </div>
                            <div className='img_check_in_ig'>
                                <img src={ig6} />
                            </div>
                        </Slider>
                    </div>
                    <CaracteristicasMarcacion data={data} imgTipo={horario} />
                </div>
            </div >
        </div >
    )
}