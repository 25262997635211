import { useEffect, useState } from 'react';
import { obtenerDatos } from './getVideos';
import './questions.css';
import { useLanguage } from '../../contexts/LanguageContext';

export const Questions = () => {
    const { language, translations } = useLanguage();
    const [video, setVideo] = useState('9-bHKD5.mp4');
    const [selectedVideoId, setSelectedVideoId] = useState(null);
    const [dataVideos, setDataVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchDataVideos = async () => {
        try {
            const resultVideos = await obtenerDatos("getAllAyuda");
            setDataVideos(resultVideos || []);
        } catch (e) {
            console.error("Error al obtener videos:", e.message);
            setError("No se pudieron cargar los videos.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDataVideos();
    }, []);

    const handleSelectPregunta = (imagen, id) => {
        setVideo(imagen);
        setSelectedVideoId(id);
        const targetElement = document.getElementById(`videos`);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <div className='questions'>
            <div className='contenedor'>
                <div className="preguntas">
                    <h1>{translations[language]?.help?.titulo || "Ayuda"}</h1>
                    {loading ? (
                        <p>Cargando preguntas...</p>
                    ) : error ? (
                        <p>{error}</p>
                    ) : (
                        <ul>
                            {dataVideos.map((item) => (
                                <li key={item.id_ayuda}
                                    onClick={() => handleSelectPregunta(item.imagen, item.id_ayuda)}
                                    className={selectedVideoId === item.id_ayuda ? 'active' : ''}>
                                    <p>{item.pregunta}</p>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div id="videos" className='videos'>
                    <div className='respuesta'>
                        <div className="video_ayuda">
                            {video ? (
                                <video key={video} controls>
                                    <source src={`https://storage.googleapis.com/pinlet/Ayudas/${video}`} type="video/mp4" />
                                    Tu navegador no soporta la reproducción de video.
                                </video>
                            ) : (
                                <p>Selecciona una pregunta para ver el video.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
