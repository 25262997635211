
import marca from '../../../assets2/textoLogoPinlet.png';

import './tracking.css'

import geo from '../../../assets2/productos/trackIng/geolocalizacion.svg'
import estado from '../../../assets2/productos/trackIng/estado.svg'
import gracia from '../../../assets2/productos/trackIng/gracia.svg'
import foto from '../../../assets2/productos/trackIng/fotografia.svg'
import estrella from '../../../assets2/productos/trackIng/calificacion.svg'
import retraso from '../../../assets2/productos/trackIng/retraso.svg'
import distancia from '../../../assets2/productos/trackIng/distancia.svg'
// import ig1 from '../../../assets2/productos/checkIn/ig1.jpg'
// import ig2 from '../../../assets2/productos/checkIn/ig2.jpg'
// import ig3 from '../../../assets2/productos/checkIn/ig3.jpg'
// import ig4 from '../../../assets2/productos/checkIn/ig4.jpg'
// import ig5 from '../../../assets2/productos/checkIn/ig5.jpg'
// import ig6 from '../../../assets2/productos/checkIn/ig6.jpg'

import adaptable from '../../../assets2/productos/trackIng/module.svg'
import seccionable from '../../../assets2/productos/trackIng/users.svg'
import modulable from '../../../assets2/productos/trackIng/track.svg'
import reportable from '../../../assets2/productos/trackIng/report.svg'



import img_section1 from '../../../assets2/productos/trackIng/section_img1.png'
import img_section2 from '../../../assets2/productos/trackIng/section_img2.png'
import img_section3 from '../../../assets2/productos/trackIng/section_img3.png'
import img_section4 from '../../../assets2/productos/trackIng/section_img4.png'

import ruta from '../../../assets2/productos/trackIng/ruta.png'

import { useLanguage } from '../../../contexts/LanguageContext';
import { CaracteristicasMarcacion } from '../../../sections/CaracteristicasMarcacion/CaracteristicasMarcacion';

export const Tracking = () => {

    const { language, translations } = useLanguage();

    const data = [
        {
            caracteristica: translations[language].products.trackingSeccion.data1.adelanto,
            imgBtn: adaptable,
            titulo: translations[language].products.trackingSeccion.data1.cabeza,
            img: img_section1,
            texto: translations[language].products.trackingSeccion.data1.texto
        },
        {
            caracteristica: translations[language].products.trackingSeccion.data2.adelanto,
            imgBtn: seccionable,
            titulo: translations[language].products.trackingSeccion.data2.cabeza,
            img: img_section2,
            texto: translations[language].products.trackingSeccion.data2.texto
        },
        {
            caracteristica: translations[language].products.trackingSeccion.data3.adelanto,
            imgBtn: modulable,
            titulo: translations[language].products.trackingSeccion.data3.cabeza,
            img: img_section3,
            texto: translations[language].products.trackingSeccion.data3.texto
        },
        {
            caracteristica: translations[language].products.trackingSeccion.data4.adelanto,
            imgBtn: reportable,
            titulo: translations[language].products.trackingSeccion.data4.cabeza,
            img: img_section4,
            texto: translations[language].products.trackingSeccion.data4.texto
        },
    ];

    return (
        <div className="main_tracking">
            <div className='contenedor'>
                <div className='titulo'>
                    <img src={marca} alt='texto logo pinlet'></img>
                    <h3>{translations[language].products.tracking}</h3>
                </div>
                <div className='informacion'>
                    <p className='descripcion'>
                        {translations[language].products.trackingSeccion.descripcion}
                    </p>
                </div>
                <div className='manual'>
                    <div className='titulo_marcacion'>
                        <h3>  {translations[language].products.trackingSeccion.titulo}</h3>
                        <h4> {translations[language].products.trackingSeccion.subtitulo} </h4>
                    </div>
                    <div className='puntos'>
                        <div className='punto'>
                            <h4>{translations[language].products.trackingSeccion.puntos.punto1.titulo}</h4>
                            <img src={geo}></img>
                            <p>{translations[language].products.trackingSeccion.puntos.punto1.texto}</p>
                        </div>

                        <div className='punto'>
                            <h4>{translations[language].products.trackingSeccion.puntos.punto2.titulo}</h4>
                            <img src={estado}></img>
                            <p>{translations[language].products.trackingSeccion.puntos.punto2.texto}</p>
                        </div>

                        <div className='punto'>
                            <h4>{translations[language].products.trackingSeccion.puntos.punto3.titulo}</h4>
                            <img src={gracia}></img>
                            <p>{translations[language].products.trackingSeccion.puntos.punto3.texto}</p>
                        </div>

                        <div className='punto'>
                            <h4>{translations[language].products.trackingSeccion.puntos.punto4.titulo}</h4>
                            <img src={foto}></img>
                            <p>{translations[language].products.trackingSeccion.puntos.punto4.texto}</p>
                        </div>

                        <div className='punto'>
                            <h4>{translations[language].products.trackingSeccion.puntos.punto5.titulo}</h4>
                            <img src={estrella}></img>
                            <p>{translations[language].products.trackingSeccion.puntos.punto5.texto}</p>
                        </div>

                        <div className='punto'>
                            <h4>{translations[language].products.trackingSeccion.puntos.punto6.titulo}</h4>
                            <img src={retraso}></img>
                            <p>{translations[language].products.trackingSeccion.puntos.punto6.texto}</p>
                        </div>

                        <div className='punto'>
                            <h4>{translations[language].products.trackingSeccion.puntos.punto7.titulo}</h4>
                            <img src={distancia}></img>
                            <p>{translations[language].products.trackingSeccion.puntos.punto7.texto}</p>
                        </div>
                    </div>
                    <CaracteristicasMarcacion data={data} imgTipo={ruta} />
                </div>
            </div>
        </div>
    )
}