import marca from '../../../assets2/textoLogoPinlet.png';
import { useLanguage } from '../../../contexts/LanguageContext';
import './scan.css'

import ig1 from '../../../assets2/productos/scan/ig1.jpg'
import ig2 from '../../../assets2/productos/scan/ig2.jpg'
import ig3 from '../../../assets2/productos/scan/ig3.jpg'
import ig4 from '../../../assets2/productos/scan/ig4.jpg'
import ig5 from '../../../assets2/productos/scan/ig5.jpg'

import adaptable from '../../../assets2/productos/scan/area.svg'
import seccionable from '../../../assets2/productos/scan/users.svg'
import modulable from '../../../assets2/productos/scan/ajuste.svg'
import reportable from '../../../assets2/productos/scan/report.svg'

import img_section1 from '../../../assets2/productos/scan/section_img1.png'
import img_section2 from '../../../assets2/productos/scan/section_img2.png'
import img_section3 from '../../../assets2/productos/scan/section_img3.png'
import img_section4 from '../../../assets2/productos/scan/section_img4.png'
import { CaracteristicasMarcacion } from '../../../sections/CaracteristicasMarcacion/CaracteristicasMarcacion';

import qr from '../../../assets2/productos/scan/qr.png'

import Slider from "react-slick";

export const Scan = () => {

    const { language, translations } = useLanguage();

    const settingsCarrusel = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        centerMode: true,
        centerPadding: "0",
        responsive: [
            {
                breakpoint: 1250,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "20px",
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    centerMode: false,
                },
            },
        ],
    };

    const data = [
        {
            caracteristica: translations[language].products.scanSeccion.data1.adelanto,
            imgBtn: adaptable,
            titulo: translations[language].products.scanSeccion.data1.cabeza,
            img: img_section1,
            texto: translations[language].products.scanSeccion.data1.texto
        },
        {
            caracteristica: translations[language].products.scanSeccion.data2.adelanto,
            imgBtn: seccionable,
            titulo: translations[language].products.scanSeccion.data2.cabeza,
            img: img_section2,
            texto: translations[language].products.scanSeccion.data2.texto
        },
        {
            caracteristica: translations[language].products.scanSeccion.data3.adelanto,
            imgBtn: modulable,
            titulo: translations[language].products.scanSeccion.data3.cabeza,
            img: img_section3,
            texto: translations[language].products.scanSeccion.data3.texto
        },
        {
            caracteristica: translations[language].products.scanSeccion.data4.adelanto,
            imgBtn: reportable,
            titulo: translations[language].products.scanSeccion.data4.cabeza,
            img: img_section4,
            texto: translations[language].products.scanSeccion.data4.texto
        },
    ];

    return (
        <div className="main_scan">
            <div className='contenedor'>
                <div className='titulo'>
                    <img src={marca} alt='texto logo pinlet'></img>
                    <h3>{translations[language].products.scan}</h3>
                </div>
                <div className='informacion'>
                    <p className='descripcion'>
                        {translations[language].products.scanSeccion.descripcion}
                    </p>
                </div>
                <div className='manual'>
                    <div className='titulo_marcacion'>
                        <h3> {translations[language].products.scanSeccion.titulo}</h3>
                        <h4> {translations[language].products.scanSeccion.subtitulo} </h4>
                    </div>
                    <div className='slide-carrusel'>
                        <Slider {...settingsCarrusel}>
                            <div className='img_check_in_ig'>
                                <img src={ig5} />
                            </div>
                            <div className='img_check_in_ig'>
                                <img src={ig1} />
                            </div>
                            <div className='img_check_in_ig'>
                                <img src={ig2} />
                            </div>
                            <div className='img_check_in_ig'>
                                <img src={ig3} />
                            </div>
                            <div className='img_check_in_ig'>
                                <img src={ig4} />
                            </div>
                        </Slider>
                    </div>
                    <CaracteristicasMarcacion data={data} imgTipo={qr} />
                </div>
            </div>
        </div>
    )
}