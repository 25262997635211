

import { Outlet, NavLink, Route, Routes } from "react-router-dom";
import Movil from './Movil/Movil';
import Bitacora from './Bitacora/Bitacora';
import BitacoraMovil from './BitacoraMovil/BitacoraMovil';
import iconBitacoraMovil from '../../assets2/productos/bitacoraMovil.png';
import iconScan from '../../assets2/productos/scan.png'
import iconTracking from '../../assets2/productos/tracking.png'
import iconCheckIng from '../../assets2/productos/check_in.png'
import iconMovil from '../../assets2/productos/movil.png';
import iconBitacora from '../../assets2/productos/bitacora.png';
import { useLanguage } from '../../contexts/LanguageContext';

import './productos.css';
import { CheckIn } from "./CheckIn/checkIn";
import { Scan } from "./Scan/Scan";
import { Tracking } from "./Tracking/tracking";

function Productos() {

    const { language, translations } = useLanguage();

    return (
        <div className="main_productos">
            <div className="contenedor">
                <nav>
                    <ul className="opciones_productos">
                        <li>
                            <NavLink to="movil" className={({ isActive }) => isActive ? 'active' : ''}>
                                <img src={iconMovil} alt="Movil"></img>
                                <p className="texto_productos">{translations[language].products.movil}</p>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="bitacora" className={({ isActive }) => isActive ? 'active' : ''}>
                                <img src={iconBitacora} alt="Bitacora"></img>
                                <p className="texto_productos">{translations[language].products.bitacora}</p>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="bitacoraMovil" className={({ isActive }) => isActive ? 'active' : ''}>
                                <img src={iconBitacoraMovil} alt="BitacoraMovil"></img>
                                <p className="texto_productos">{translations[language].products.bitacoraMovil}</p>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="check-in" className={({ isActive }) => isActive ? 'active' : ''}>
                                <img src={iconCheckIng} alt="CheckIn"></img>
                                <p className="texto_productos">{translations[language].products.checkIn}</p>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="scan" className={({ isActive }) => isActive ? 'active' : ''}>
                                <img src={iconScan} alt="Scan"></img>
                                <p className="texto_productos">{translations[language].products.scan}</p>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="tracking" className={({ isActive }) => isActive ? 'active' : ''}>
                                <img src={iconTracking} alt="Tracking"></img>
                                <p className="texto_productos">{translations[language].products.tracking}</p>
                            </NavLink>
                        </li>
                    </ul>
                </nav>
                <Outlet />
            </div>
        </div>
    );
}

function ProductosRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Productos />}>
                <Route path="movil" element={<Movil />} />
                <Route path="bitacora" element={<Bitacora />} />
                <Route path="bitacoraMovil" element={<BitacoraMovil />} />
                <Route path="check-in" element={<CheckIn />} />
                <Route path="scan" element={<Scan />} />
                <Route path="tracking" element={<Tracking />} />
            </Route>
        </Routes>
    );
}

export default ProductosRoutes;