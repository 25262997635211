import { useState, useEffect } from 'react';
import Slider from "react-slick";
import './clientsSection.css'

import { useLanguage } from '../../contexts/LanguageContext';

import event1 from '../../assets2/Funtions/clientes/event1.jpeg'
import event2 from '../../assets2/Funtions/clientes/event2.jpeg'
import event3 from '../../assets2/Funtions/clientes/event3.jpeg'
import event4 from '../../assets2/Funtions/clientes/event4.jpeg'
import event5 from '../../assets2/Funtions/clientes/event4.jpeg'

import { callClientess } from './Clientes';

import tenisClub from '../../assets2/Funtions/clientes/tenis-club.png'
import bancoGuayaquil from '../../assets2/Funtions/clientes/banco-guayaquil.png'
import pacificaCenter from '../../assets2/Funtions/clientes/pacifica-center.png'
import ulvr from '../../assets2/Funtions/clientes/ulvr.png'
import casaGrande from '../../assets2/Funtions/clientes/casa-grande.png'
import arcosPlaza from '../../assets2/Funtions/clientes/arcos-plaza.png'
import solca from '../../assets2/Funtions/clientes/solca.png'
import puntaMocoli from '../../assets2/Funtions/clientes/punta-mocoli.png'
import almax from '../../assets2/Funtions/clientes/almax.png'

// https://react-slick.neostack.com/d/ocs/get-started



const eventSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
};

export const ClientsSection2 = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const { language, translations } = useLanguage();
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };

  }, []);

  let data = null;




  const obtenerClientes = async () => {
    try {
      data = await callClientess(); // Llama y espera los datos
      console.log("data: ", data);
      console.log("data cantidad: ", data.length);
      console.log("es array?: ", Array.isArray(data));
      setClientes(data)
    } catch (error) {
      console.error("Error al obtener los datos en el componente:", error);
    }
  };

  useEffect(() => {
    obtenerClientes();
  }, []);



  return (
    <div id="clients-section" className='clients-section pn-bg-orange'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>

            <div className='row events-wrapper'>
              <div className='col-12'>
                <div className='row'>
                  <h3 className='header-title'>{translations[language].home.cliente.eventos.titulo}</h3>
                </div>
                <div className='col-12 col-md-10 offset-md-1 mt-4'>
                  <div className='row'>
                    <Slider {...((windowSize.innerWidth > 576) ? ({ ...eventSettings }) : ({ ...eventSettings, slidesToShow: 1 }))}>
                      <img className='img-fluid p-2' src={event1} alt="Imagen Evento 1" loading="lazy" />
                      <img className='img-fluid p-2' src={event2} alt="Imagen Evento 2" loading="lazy" />
                      <img className='img-fluid p-2' src={event3} alt="Imagen Evento 3" loading="lazy" />
                      <img className='img-fluid p-2' src={event4} alt="Imagen Evento 4" loading="lazy" />
                      <img className='img-fluid p-2' src={event5} alt="Imagen Evento 4" loading="lazy" />
                    </Slider>
                  </div>
                </div>

                <div style={{ marginTop: "100px" }}></div>

                <div className='clientes'>
                  <h3 className='header-title'>Nuestros Cliente</h3>
                  <div className='list'>
                    {clientes.map((cliente) =>
                      cliente.estado === "A" ? (
                        <div key={cliente.id_lugar} className='cliente_container'>
                          <div className='img_container'>
                            <img
                              src={`https://storage.googleapis.com/pinlet/Lugar/${cliente.imagen}`}
                              alt={cliente.nombre}
                            />
                          </div>
                          <p className='nombre'>{cliente.nombre}</p>
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  )
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}