const cache = new Map(); // Caché en memoria

export const callClientess = async () => {
  const apiUrl = "https://pinlet.app/apiRest6/corp/v1/getAllugar_web";
  const cacheKey = "id_residente_1"; // Clave fija para la caché

  // Verificamos si la respuesta ya está en la caché
  if (cache.has(cacheKey)) {
    return cache.get(cacheKey); // Retornamos los datos almacenados
  }

  try {
    const apiKey = process.env.REACT_APP_API_KEY_LUGAR; // Verifica que esta clave esté configurada correctamente
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiKey}`,
    };

    const response = await fetch(apiUrl, {
      method: "GET",
      headers: headers
    });

    // Verificamos si la respuesta fue exitosa
    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.status}`);
    }

    const data = await response.json(); // Parseamos la respuesta como JSON

    // Guardamos los datos en la caché
    cache.set(cacheKey, data);

    return data; // Retornamos los datos
  } catch (err) {
    console.error("Error en la solicitud:", err);
    throw err; // Lanza el error para manejarlo externamente
  }
};